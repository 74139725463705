<template>
    <div>
      <vs-table search stripe border description :sst="true" :data="exportReport" :max-items="table.length"
        :total="table.total" @search="handleSearch" @change-page="handleChangePage" @sort="handleSort">
        <template slot="header">
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div class="
                  p-3
                  border border-solid
                  d-theme-border-grey-light
                  rounded-full
                  d-theme-dark-bg
                  cursor-pointer
                  flex
                  items-center
                  justify-between
                  font-medium
                ">
              <span class="mr-2">{{ this.table.start }} - {{ this.table.end }} of
                {{ this.table.total }}</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <vs-dropdown-menu>
              <vs-dropdown-item v-for="item in table.limits" :key="item" @click="handleChangelength(item)">
                <span>{{ item }}</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </template>
        <template slot="thead">
          <vs-th sort-key="shipment_number">Shipment Number</vs-th>
          <vs-th sort-key="shipment_date">Shipment date</vs-th>
          <vs-th sort-key="shipment_status">Shipment Status</vs-th>
          <vs-th sort-key="total_pi">Total PI</vs-th>
          <vs-th sort-key="ownership">Ownership</vs-th>
          <vs-th sort-key="driver_type">Driver Type</vs-th>
          <vs-th sort-key="trans_plan_poin">Trans Plan Poin</vs-th>
          <vs-th sort-key="driver_name">Driver Name</vs-th>
          <vs-th sort-key="vehicle_no">Vehicle No</vs-th>
          <vs-th sort-key="vehicle_type">Vehicle Type</vs-th>
          <vs-th sort-key="vendor_code">Vendor Code</vs-th>
          <vs-th sort-key="vendor_name">Vendor Name</vs-th>
          <vs-th sort-key="so_number">SO Number</vs-th>
          <vs-th sort-key="pi_number">PI Number</vs-th>
          <vs-th sort-key="sold_to_code">Sold to Code</vs-th>
          <vs-th sort-key="sold_to_name">Sold to Name</vs-th>
          <vs-th sort-key="sold_to_bill_code">Sold to Bill Code</vs-th>
          <vs-th sort-key="sold_to_bill_name">Sold to Bill Name</vs-th>
          <vs-th sort-key="ship_to_code">Ship to Code</vs-th>
          <vs-th sort-key="ship_to_name">Ship to Name</vs-th>
          <vs-th sort-key="customer_address">Customer Address</vs-th>
          <vs-th sort-key="salesman">Salesman</vs-th>
          <vs-th sort-key="order_type">Order Type</vs-th>
          <vs-th sort-key="sku_code">SKU Code</vs-th>
          <vs-th sort-key="sku_name">SKU Name</vs-th>
          <vs-th sort-key="quantity">Quantity</vs-th>
          <vs-th sort-key="uom">UOM</vs-th>
          <vs-th sort-key="amount_pi">Amount PI Net</vs-th>
          <vs-th sort-key="grand_total">Grand Total</vs-th>
          <vs-th sort-key="status_pod">Status POD</vs-th>
          <vs-th sort-key="shipment_cost_no">Shipment Cost No</vs-th>
          <vs-th sort-key="km_berangkat">KM Berangkat</vs-th>
          <vs-th sort-key="km_kembali">KM Kembali</vs-th>
          <vs-th sort-key="shipment_cost_value">Shipment Cost Value</vs-th>
          <vs-th sort-key="status_shipment">Status Shipment</vs-th>
          <vs-th sort-key="jumlah_customer">Jumlah Customer</vs-th>
          <vs-th sort-key="distance">Distance</vs-th>
          <vs-th sort-key="distance_uom">Distance UOM</vs-th>
          <vs-th sort-key="nilai_realisasi_shipment_cost">Nilai Realisasi Shipment Cost</vs-th>
          <vs-th sort-key="biaya_bbm">Biaya BBM</vs-th>
          <vs-th sort-key="biaya_kuli">Biaya Kuli</vs-th>
          <vs-th sort-key="biaya_parkir">Biaya Parkir</vs-th>
          <vs-th sort-key="biaya_keamanan">Biaya Keamanan</vs-th>
          <vs-th sort-key="biaya_retribusi">Biaya Retribusi</vs-th>
          <vs-th sort-key="biaya_toll">Biaya Toll</vs-th>
          <vs-th sort-key="biaya_other">Biaya Other</vs-th>
          <vs-th sort-key="cash_out_doc_no">Cash Out Doc No(Odoo)</vs-th>
          <vs-th sort-key="cash_out_date">Cash Out Date(Odoo)</vs-th>
          <vs-th sort-key="cash_out_value">Cash Out Value(Odoo)</vs-th>
          <vs-th sort-key="refund">Refund</vs-th>
          <vs-th sort-key="no_doc_realisasi">No Doc Realisasi (Odoo)</vs-th>
          <vs-th sort-key="realisasi_date">Realisasi Date (Odoo)</vs-th>
          <vs-th sort-key="status_realisasi_shipment_cost">Status Realisasi Shipment Cost</vs-th>
        </template>
  
        <template slot-scope="{ data }">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="data[indextr].shipment_number">
              {{ data[indextr].shipment_number }}
            </vs-td>
            <vs-td :data="data[indextr].shipment_date">
              {{ dateFormat(data[indextr].shipment_date) }}
            </vs-td>
            <vs-td :data="data[indextr].shipment_status">
              {{ data[indextr].shipment_status }}
            </vs-td>
            <vs-td :data="data[indextr].total_pi">
              {{ data[indextr].total_pi }}
            </vs-td>
            <vs-td :data="data[indextr].ownership">
              {{ data[indextr].ownership }}
            </vs-td>
            <vs-td :data="data[indextr].driver_type">
              {{ data[indextr].driver_type }}
            </vs-td>
            <vs-td :data="data[indextr].trans_plan_poin">
              {{ data[indextr].trans_plan_poin }}
            </vs-td>
            <vs-td :data="data[indextr].driver_name">
              {{ data[indextr].driver_name }}
            </vs-td>
            <vs-td :data="data[indextr].vehicle_no">
              {{ data[indextr].vehicle_no }}
            </vs-td>
            <vs-td :data="data[indextr].vehicle_type">
              {{ data[indextr].vehicle_type }}
            </vs-td>
            <vs-td :data="data[indextr].vendor_code">
              {{ data[indextr].vendor_code }}
            </vs-td>
            <vs-td :data="data[indextr].vendor_name">
              {{ data[indextr].vendor_name }}
            </vs-td>
            <vs-td :data="data[indextr].so_number">
              {{ data[indextr].so_number }}
            </vs-td>
            <vs-td :data="data[indextr].pi_number">
              {{ data[indextr].pi_number }}
            </vs-td>
            <vs-td :data="data[indextr].sold_to_code">
              {{ data[indextr].sold_to_code }}
            </vs-td>
            <vs-td :data="data[indextr].sold_to_name">
              {{ data[indextr].sold_to_name }}
            </vs-td>
            <vs-td :data="data[indextr].sold_to_bill_code">
                {{ data[indextr].sold_to_bill_code }}
            </vs-td>
            <vs-td :data="data[indextr].sold_to_bill_name">
                {{ data[indextr].sold_to_bill_name }}
            </vs-td>
            <vs-td :data="data[indextr].ship_to_code">
              {{ data[indextr].ship_to_code }}
            </vs-td>
            <vs-td :data="data[indextr].ship_to_name">
              {{ data[indextr].ship_to_name }}
            </vs-td>
            <vs-td :data="data[indextr].customer_address">
              {{ data[indextr].customer_address }}
            </vs-td>
            <vs-td :data="data[indextr].salesman">
              {{ data[indextr].salesman }}
            </vs-td>
            <vs-td :data="data[indextr].order_type">
              {{ data[indextr].order_type }}
            </vs-td>
            <vs-td :data="data[indextr].sku_code">
              {{ data[indextr].sku_code }}
            </vs-td>
            <vs-td :data="data[indextr].sku_name">
              {{ data[indextr].sku_name }}
            </vs-td>
            <vs-td :data="data[indextr].quantity">
              {{ data[indextr].quantity }}
            </vs-td>
            <vs-td :data="data[indextr].uom">
              {{ data[indextr].uom }}
            </vs-td>
            <vs-td :data="data[indextr].amount_pi">
              {{ data[indextr].amount_pi }}
            </vs-td>
            <vs-td :data="data[indextr].grand_total">
              {{ data[indextr].grand_total }}
            </vs-td>
            <vs-td :data="data[indextr].status_pod">
              {{ data[indextr].status_pod }}
            </vs-td>
            <vs-td :data="data[indextr].shipment_cost_no">
              {{ data[indextr].shipment_cost_no }}
            </vs-td>
            <vs-td :data="data[indextr].km_berangkat">
              {{ data[indextr].km_berangkat }}
            </vs-td>
            <vs-td :data="data[indextr].km_kembali">
              {{ data[indextr].km_kembali }}
            </vs-td>
            <vs-td :data="data[indextr].shipment_cost_value">
              {{ data[indextr].shipment_cost_value }}
            </vs-td>
            <vs-td :data="data[indextr].status_shipment">
              {{ data[indextr].status_shipment }}
            </vs-td>
            <vs-td :data="data[indextr].jumlah_customer">
              {{ data[indextr].jumlah_customer }}
            </vs-td>
            <vs-td :data="data[indextr].distance">
              {{ data[indextr].distance }}
            </vs-td>
            <vs-td :data="data[indextr].distance_uom">
              {{ data[indextr].distance_uom }}
            </vs-td>
            <vs-td :data="data[indextr].nilai_realisasi_shipment_cost">
              {{ data[indextr].nilai_realisasi_shipment_cost }}
            </vs-td>
            <vs-td :data="data[indextr].biaya_bbm">
              {{ data[indextr].biaya_bbm }}
            </vs-td>
            <vs-td :data="data[indextr].biaya_kuli">
              {{ data[indextr].biaya_kuli }}
            </vs-td>
            <vs-td :data="data[indextr].biaya_parkir">
              {{ data[indextr].biaya_parkir }}
            </vs-td>
            <vs-td :data="data[indextr].biaya_keamanan">
              {{ data[indextr].biaya_keamanan }}
            </vs-td>
            <vs-td :data="data[indextr].biaya_retribusi">
              {{ data[indextr].biaya_retribusi }}
            </vs-td>
            <vs-td :data="data[indextr].biaya_toll">
              {{ data[indextr].biaya_toll }}
            </vs-td>
            <vs-td :data="data[indextr].biaya_other">
              {{ data[indextr].biaya_other }}
            </vs-td>
            <vs-td :data="data[indextr].cash_out_doc_no">
              {{ data[indextr].cash_out_doc_no }}
            </vs-td>
            <vs-td :data="data[indextr].cash_out_date">
              {{ data[indextr].cash_out_date }}
            </vs-td>
            <vs-td :data="data[indextr].cash_out_value">
              {{ data[indextr].cash_out_value }}
            </vs-td>
            <vs-td :data="data[indextr].refund">
              {{ data[indextr].refund }}
            </vs-td>
            <vs-td :data="data[indextr].no_doc_realisasi">
              {{ data[indextr].no_doc_realisasi }}
            </vs-td>
            <vs-td :data="data[indextr].realisasi_date">
              {{ data[indextr].realisasi_date }}
            </vs-td>
            <vs-td :data="data[indextr].status_realisasi_shipment_cost">
              {{ data[indextr].status_realisasi_shipment_cost }}
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <vs-pagination style="padding-top: 5px" :total="exportReportTotalPage" v-model="setPage" />
    </div>
  </template>
  <script>
  import moment from "moment";
  import { mapState, mapActions } from "vuex/dist/vuex.common.js";
  export default {
    data() {
      return {
        deleteId: null,
        table: this.tableDefaultState(),
      };
    },
    methods: {
      ...mapActions({
        exportShipmentPlanningReport: 'reportShipmentPlanning/exportShipmentPlanningReport',
        downloadShipmentPlanningReport: 'reportShipmentPlanning/downloadShipmentPlanningReport',
      }),
      tableDefaultState() {
        return {
          data: [],
          length: 10,
          page: 1,
          search: "",
          order: "id",
          sort: "desc",
          total: 0,
          totalPage: 0,
          totalSearch: 0,
          limits: [10, 25, 50, 100, "All"],
          start: 1,
          end: 0,
          heads: [
            {
              field: "created_at",
              title: "Created At",
            },
            {
              field: "created_by",
              title: "Created By",
            },
            {
              field: "file_path",
              title: "File",
            },
            {
              field: "status",
              title: "Status",
            },
          ],
        };
      },
      handleSearch(searching) {
        this.table.search = searching;
        this.table.page = 1;
        this.getData();
      },
      handleChangePage(page) {
        this.table.page = page;
        this.getData();
      },
      handleSort(key, active) {
        this.table.order = key;
        this.table.sort = active;
        this.getData();
      },
      handleChangelength(val) {
        this.table.length = val == "All" ? this.table.total : val;
        this.table.page = 1;
        this.getData();
      },
      handleClose() {
        this.getData();
        this.$emit("close");
      },
      handleFilePath(data) {
        this.downloadShipmentPlanningReport({ file_name: data }).then(
          () => { }
        )
      },
      getData() {
        this.$vs.loading();
        this.exportShipmentPlanningReport({
          length: this.table.length,
          page: this.table.page,
          search: this.table.search,
          order: this.table.order,
          sort: this.table.sort,
          type: "SDNReportShipmentPlanning",
        }).then(
          (resp) => {
            this.$vs.loading.close();
          }
        )
      },
      setStartEnd() {
        let valStart =
          this.table.length * this.table.page - this.table.length + 1;
  
        if (valStart > this.table.total) {
          valStart = 1;
        }
        if (this.table.total == 0) {
          valStart = 0;
        }
        let valEnd = this.table.length * this.table.page;
  
        if (valEnd > this.table.total) {
          valEnd = this.table.total;
        }
  
        if (
          this.table.totalSearch < this.table.total &&
          this.table.search != ""
        ) {
          valEnd = this.table.totalSearch;
        }
  
        this.table.start = valStart;
        this.table.end = valEnd;
      },
      dateFormat(val) {
        return moment(val).format("DD MMM YYYY");
      },
    },
    watch: {
      detail() {
        this.getData();
      },
      startDate() {
        this.getData();
      },
      endDate() {
        this.getData();
      },
      downloadReport(val) {
        const type = val.data.type
        const url = window.URL.createObjectURL(val.data, {type: type, encoding: 'UTF-8'});
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', val.name);
        
        // Append to html link element page
        document.body.appendChild(link);
  
        // Start download
        link.click();
      }
    },
    computed: {
      ...mapState({
        exportReport: (state) => state.reportShipmentPlanning.exportReport,
        exportReportTotalPage: (state) => state.reportShipmentPlanning.exportReportTotalPage,
        downloadReport: (state) => state.reportShipmentPlanning.downloadReport,
      }),
      setPage: {
        get() {
          return 1;
        },
        set(val) {
          this.handleChangePage(val);
        },
      },
    },
  };
  </script>